import { Switch, Route } from 'react-router-dom';

import { LoginPage, NewPasswordPage } from 'auth/pages/login';
import {
  ListInternalUsersPage,
  CreateInternalUsersPage,
  EditInternalUsersPage,
} from 'internalUsers/pages';
import {
  ListPowerDistributionUnitsPage,
  CreatePowerDistributionUnitsPage,
} from 'powerDistributionUnits/pages';
import { CreateCooperativesPage } from 'cooperatives/pages';
import { ListCooperativesPage } from 'cooperatives/pages';
import { Dashboard } from 'dashboard/components/dashboard';
import PrivateRoute from './PrivateRoute';

const Routes = () => (
  <Switch>
    <PrivateRoute
      path="/dashboard/internal-users/create"
      component={CreateInternalUsersPage}
    />
    <PrivateRoute
      path="/dashboard/internal-users/:id/edit"
      component={EditInternalUsersPage}
    />
    <PrivateRoute
      path="/dashboard/internal-users"
      component={ListInternalUsersPage}
    />
    <PrivateRoute
      path="/dashboard/power-distribution-units/create"
      component={CreatePowerDistributionUnitsPage}
    />
    <PrivateRoute
      path="/dashboard/cooperatives/create"
      component={CreateCooperativesPage}
    />
    <PrivateRoute
      path="/dashboard/power-distribution-units"
      component={ListPowerDistributionUnitsPage}
    />
    <PrivateRoute path="/dashboard/cooperatives" component={ListCooperativesPage} />
    <PrivateRoute path="/dashboard" component={Dashboard} />
    <Route path="/sign-in/new-password" component={NewPasswordPage} />
    <Route path="/" component={LoginPage} />
  </Switch>
);

export default Routes;
